'use strict';

function getCookiebotConsentFromUA() {
    var ua = navigator.userAgent;

    if (/AppMobileBVSiCookie/i.test(ua)) {
        return { preferences: true, statistics: true, marketing: true };
    }
    if (/AppMobileBVNoCookie/i.test(ua)) {
        return { preferences: false, statistics: false, marketing: false };
    }

    return null;
}

window.addEventListener('CookiebotOnDialogInit', function () {
    var mobileConsent = getCookiebotConsentFromUA();

    if (mobileConsent) {
        Cookiebot.submitCustomConsent(mobileConsent.preferences, mobileConsent.statistics, mobileConsent.marketing);
        Cookiebot.hide();
    }
});
